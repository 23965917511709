import React from 'react';
import * as theme from '../../theme';
import { SectionHeader, BreadCrumbs } from '../../components/page';
import headerImage from './section-header.jpg';
import Timeline from './timeline';
import Layout from '../../layout';
import worksheets from './worksheets.svg';
import { ActivityBox } from '../../components/boxes';
import { withPrefix } from 'gatsby';

const AWalkThroughTime = () => {
  return (
    <Layout title="A Walk Through Time">
      <SectionHeader color={theme.colors.purple.dark} image={headerImage}>A walk through time</SectionHeader>
      <BreadCrumbs items={[
        { name: 'A walk through time' }
      ]} />
      <Timeline />
      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>
    </Layout>
  );
}

export default AWalkThroughTime